import classNames from 'classnames';
import styles from 'components/Link/Link.module.scss';

const Link = ({ className, children, path, type }) => {
	return (
		<a
			className={classNames(
				styles.link,
				(type === 'body' && styles.bodyLink) || styles.headerLink,
				className
			)}
			href={path}
		>
			{children}
		</a>
	);
};

export default Link;
