import classnames from 'classnames';
import nodeIcon from 'components/Tag/assets/Node.svg';
import mongoDBIcon from 'components/Tag/assets/MongoDB.svg';
import reactIcon from 'components/Tag/assets/React.svg';
import pythonIcon from 'components/Tag/assets/Python.svg';
import flaskIcon from 'components/Tag/assets/Flask.svg';
import postgresqlIcon from 'components/Tag/assets/Postgressql.svg';
import scssIcon from 'components/Tag/assets/SCSS.svg';
import htmlIcon from 'components/Tag/assets/Html.svg';
import djangoIcon from 'components/Tag/assets/Django.svg';
import expressIcon from 'components/Tag/assets/Express.svg';
import firebaseIcon from 'components/Tag/assets/Firebase.svg';
import chakraUIIcon from 'components/Tag/assets/ChakraUI.svg';
import reduxIcon from 'components/Tag/assets/Redux.svg';
import styles from 'components/Tag/Tag.module.scss';

const Tag = (props) => {
    const { icon, className } = props;

    const icons = {
        'Node.js': nodeIcon,
        MongoDB: mongoDBIcon,
        React: reactIcon,
        Python: pythonIcon,
        Flask: flaskIcon,
        SCSS: scssIcon,
        PostgreSQL: postgresqlIcon,
        HTML: htmlIcon,
        Django: djangoIcon,
        Express: expressIcon,
        Firebase: firebaseIcon,
        'Chakra UI': chakraUIIcon,
        Redux: reduxIcon,
    };

    return (
        <div className={classnames(styles.tagContainer, className)}>
            <img src={icons[icon]} alt='' className={styles.tagIcon} />
            <p className={styles.tagText}>{icon}</p>
        </div>
    );
};

export default Tag;
