import classnames from 'classnames';
import Tag from 'components/Tag/Tag';
import githubIcon from 'assets/icons/github.svg';
import newWindowIcon from 'assets/icons/newWindow.svg';
import styles from 'components/Project/Project.module.scss';

const Project = (props) => {
	const { tools, title, description, mainLink, githubLink, appLink, coverImage } = props;

	const getToolTags = () => tools.map((tool) => <Tag icon={tool} />);

	return (
		<div className={classnames(styles.projectContainer)} onClick={() => window.open(mainLink)}>
			<div className={styles.projectUpperWrapper}>
				<div className={styles.projectLinksContainer}>
					{githubLink && (
						<img
							src={githubIcon}
							alt='github logo'
							className={styles.projectGithubIcon}
							onClick={() => window.open(githubLink)}
						/>
					)}
					{appLink && (
						<img
							src={newWindowIcon}
							alt='arrow pointing diagonally icon'
							className={styles.projectNewWindowIcon}
							onClick={() => window.open(appLink)}
						/>
					)}
				</div>
				<div
					className={styles.projectCoverImage}
					style={{ backgroundImage: `url(${coverImage})` }}
				/>
			</div>
			<h3 className={styles.projectName}>{title}</h3>
			<div className={styles.projectToolsContainer}>{getToolTags()}</div>
			<p className={styles.projectDescription}>{description}</p>
		</div>
	);
};

export default Project;
