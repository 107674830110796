import classnames from 'classnames';
import { useState, useEffect, useRef } from 'react';

import Button from 'components/Button/Button';
import Link from 'components/Link/Link';
import whiteCompassIcon from 'assets/icons/whiteCompass.svg';
import blackChevronIcon from 'assets/icons/blackChevron.svg';
import numberOneIcon from 'assets/icons/numberOne.svg';
import numberTwoIcon from 'assets/icons/numberTwo.svg';
import numberThreeIcon from 'assets/icons/numberThree.svg';
import githubIcon from 'assets/icons/github.svg';
import linkedInIcon from 'assets/icons/linkedIn.svg';
import emailIcon from 'assets/icons/email.svg';
import profileIcon from 'assets/icons/profile.svg';
import briefcaseIcon from 'assets/icons/briefcase.svg';
import paletteIcon from 'assets/icons/palette.svg';
import contactIcon from 'assets/icons/emailCircle.svg';
import devpostIcon from 'assets/icons/devpost.svg';
import dribbbleIcon from 'assets/icons/dribbble.svg';
import profilePictureSmall from 'assets/profilePictureSmall.svg';
import profilePictureLarge from 'assets/profilePictureLarge.svg';
import beaconCoverImage from 'assets/beaconCoverImage.svg';
import roadrunnerCoverImage from 'assets/roadrunnerCoverImage.svg';
import polarisCoverImage from 'assets/polarisCoverImage.svg';
import blitzCoverImage from 'assets/blitzCoverImage.svg';
import htmlAnimationIcon from 'assets/icons/htmlAnimationIcon.svg';
import mongoDbAnimationIcon from 'assets/icons/mongoDbAnimationIcon.svg';
import nodeJsAnimationIcon from 'assets/icons/nodeJsAnimationIcon.svg';
import postgresAnimationIcon from 'assets/icons/postgresAnimationIcon.svg';
import pythonAnimationIcon from 'assets/icons/pythonAnimationIcon.svg';
import reactAnimationIcon from 'assets/icons/reactAnimationIcon.svg';
import sliderMeasurements from 'assets/json/sliderMeasurements.json';
import jobData from 'assets/json/positionData.json';
import styles from 'app/App.module.scss';
import 'app/global.scss';
import Project from 'components/Project/Project';

const App = () => {
    const [activeSliderButton, setActiveSliderButton] = useState(0);
    const [offsetLeft, setOffsetLeft] = useState(30);
    const [offsetWidth, setOffsetWidth] = useState(60);

    const aboutMeRef = useRef(null);
    const htmlIconRef = useRef(null);
    const mongoDbIconRef = useRef(null);
    const nodeJsIconRef = useRef(null);
    const postgresIconRef = useRef(null);
    const pythonIconRef = useRef(null);
    const reactIconRef = useRef(null);

    useEffect(() => {
        const animationIcons = [htmlIconRef, mongoDbIconRef, nodeJsIconRef, postgresIconRef, pythonIconRef, reactIconRef];
        const increase = (Math.PI * 2) / animationIcons.length;
        let x = 0;
        let y = 0;
        let angle = 0;
        animationIcons.forEach((icon) => {
            x = 165 * Math.cos(angle) + 142.5;
            y = 165 * Math.sin(angle) + 142.5;
            icon.current.style.position = 'absolute';
            icon.current.style.left = x + 'px';
            icon.current.style.top = y + 'px';
            angle += increase;
        });
    }, []);

    const scrollTo = (ref) => ref.current.scrollIntoView();

    const compassIconComponent = <img src={whiteCompassIcon} className={styles.compassIcon} alt='Compass Icon' />;
    const chevronIconComponent = <img src={blackChevronIcon} className={styles.buttonIcon} alt='Chevron Icon' />;

    const experienceSliderClicked = (buttonClicked) => {
        setOffsetLeft(sliderMeasurements.offsetLeft[buttonClicked]);
        setOffsetWidth(sliderMeasurements.width[buttonClicked]);
        setActiveSliderButton(buttonClicked);
    };

    const renderJob = () => {
        const job = jobData.data[activeSliderButton];
        return (
            <>
                <h3 className={styles.jobTitle}>
                    {job.position} <span className={styles.gradientText}>@ {job.company}</span>
                </h3>
                <p className={styles.jobDates}>{job.dates}</p>
                <ul className={styles.jobDescription}>
                    {job.description.map((line) => (
                        <li className={styles.jobDescriptionLine}>{line}</li>
                    ))}
                </ul>
            </>
        );
    };

    return (
        <div>
            <div className={styles.topNavigationBar}>
                <img className={styles.navigationBarProfilePicture} src={profilePictureSmall} alt='Person in a suit smiling' />
                <div className={styles.navigationLinkContainer}>
                    <img src={numberOneIcon} alt='Circle with 1 in it' />
                    <Link className={styles.navigationLink} type='header' path='#aboutMeSection'>
                        About Me
                    </Link>
                </div>
                <div className={styles.navigationLinkContainer}>
                    <img src={numberTwoIcon} alt='Circle with 2 in it' />
                    <Link className={styles.navigationLink} type='header' path='#experienceSection'>
                        Experience
                    </Link>
                </div>
                <div className={styles.navigationLinkContainer}>
                    <img src={numberThreeIcon} alt='Circle with 3 in it' />
                    <Link className={styles.navigationLink} type='header' path='#projectSection'>
                        Projects
                    </Link>
                </div>
                <div className={styles.socialIconContainer}>
                    <a href='https://linkedin.com/in/harshithlatchupatula' target='_blank' rel='noreferrer'>
                        <img className={styles.socialIcon} src={linkedInIcon} alt='LinkedIn logo' />
                    </a>
                    <a href='mailto:harshithl1777@gmail.com' target='_blank' rel='noreferrer'>
                        <img className={styles.socialIcon} src={emailIcon} alt='Mail envelope' />
                    </a>
                    <a href='https://github.com/harshithl1777' target='_blank' rel='noreferrer'>
                        <img className={styles.socialIcon} src={githubIcon} alt='Github logo' />
                    </a>
                </div>
            </div>
            <div className={styles.landingSection}>
                <h1 className={styles.landingHeader} data-aos='fade-up' data-aos-duration='1000'>
                    👋 Hey there, I’m Harshith. <br /> I’m an aspiring startup founder, immersed in all things
                    <span className={styles.gradientText}> software.</span>
                </h1>
                <p className={styles.landingBodyText} data-aos='fade-up' data-aos-duration='1000' data-aos-delay='200'>
                    I’m a Computer Science student at the University of Toronto who is extremely passionate about startups,
                    cybersecurity and <span className='bold'>great software products.</span> I also love learning new languages
                    and frameworks whenever I can. Currently, I’m doing a course on Go!
                </p>
                <div className={styles.buttonsContainer} data-aos='fade-up' data-aos-duration='1000' data-aos-delay='400'>
                    <Button type='primary' onClick={() => scrollTo(aboutMeRef)} icon={compassIconComponent} iconPlacement='front'>
                        Explore the site
                    </Button>
                    <Button
                        onClick={() => window.open('https://harshith.dev/Harshith_Latchupatula_resume.pdf')}
                        type='secondary'
                        icon={chevronIconComponent}
                        iconPlacement='back'
                    >
                        See my resume
                    </Button>
                </div>
            </div>
            <div id='aboutMeSection' className={styles.aboutMeSection} ref={aboutMeRef}>
                <div className={styles.aboutMeSectionContent}>
                    <img src={profileIcon} className={styles.profileIcon} alt='Person icon' />
                    <h3 className={classnames(styles.gradientText, styles.aboutMeTag)}>ABOUT ME</h3>
                    <h2 className={styles.aboutMeHeader}>
                        A little bit <span className={styles.gradientText}>about me</span>
                    </h2>
                    <p className={styles.aboutMeDescriptionOne}>
                        I suppose that I got into software development in an unusual way. When I was in Grade 8 or so, I was
                        helping to host an event that required me to reach out to a variety of sponsors, which is when I came
                        across the Y-Combinator startup directory which had hundreds of startups. The only issue was that going
                        through each startup’s page would take hours. I eventually found a tutorial on YouTube that showed me how
                        to web scrape any site and easily save time. Before I knew it, I was doing even more tutorials on
                        programming, and eventually got into web development.
                    </p>
                    <p className={styles.aboutMeDescriptionTwo}>
                        As for my technical skills, I’ve worked with a couple tools in my personal projects and previous
                        internships including{' '}
                        <span className='bold'>React, HTML & CSS, Node.js, Python, PostgreSQL and MongoDB.</span>
                    </p>
                </div>
                <div className={styles.aboutMePictureContainer}>
                    <div className={styles.aboutMeAnimationContainer}>
                        <img
                            className={styles.animationIcon}
                            src={htmlAnimationIcon}
                            alt='html icon in a white circle'
                            ref={htmlIconRef}
                        />
                        <img
                            className={styles.animationIcon}
                            src={mongoDbAnimationIcon}
                            alt='mongoDB icon in a white circle'
                            ref={mongoDbIconRef}
                        />
                        <img
                            className={styles.animationIcon}
                            src={nodeJsAnimationIcon}
                            alt='nodeJS icon in a white circle'
                            ref={nodeJsIconRef}
                        />
                        <img
                            className={styles.animationIcon}
                            src={postgresAnimationIcon}
                            alt='postgres icon in a white circle'
                            ref={postgresIconRef}
                        />
                        <img
                            className={styles.animationIcon}
                            src={pythonAnimationIcon}
                            alt='python icon in a white circle'
                            ref={pythonIconRef}
                        />
                        <img
                            className={styles.animationIcon}
                            src={reactAnimationIcon}
                            alt='react icon in a white circle'
                            ref={reactIconRef}
                        />
                    </div>
                    <img
                        className={styles.animationCenterProfilePicture}
                        src={profilePictureLarge}
                        alt='profile pic with a white border'
                    />
                </div>
            </div>
            <div id='experienceSection' className={styles.experienceSection}>
                <img className={styles.briefcaseIcon} src={briefcaseIcon} alt='Briefcase icon' />
                <h3 className={classnames(styles.gradientText, styles.experienceTag)}>EXPERIENCE</h3>
                <h2 className={styles.experienceHeader}>
                    Where I’ve <span className={styles.gradientText}>learned</span> and{' '}
                    <span className={styles.gradientText}>worked</span> in the past
                </h2>
                <p className={styles.experienceDescription}>
                    In the past, I’ve had the good fortune of working with some great companies and people, which has taught me so
                    much not only as a developer but as a person in general.
                </p>
                <div className={styles.experienceSliderContainer}>
                    <div className={styles.sliderBar}>
                        <div style={{ left: `${offsetLeft}px`, width: `${offsetWidth}px` }} className={styles.sliderIndicator} />
                        <div className={styles.sliderBarButtonContainer}>
                            <h3
                                onClick={(e) => experienceSliderClicked(0)}
                                className={classnames(
                                    styles.sliderButton,
                                    styles.sliderButtonFirst,
                                    activeSliderButton === 0 && styles.activeSliderButton
                                )}
                            >
                                IBM
                            </h3>
                            <h3
                                onClick={() => experienceSliderClicked(1)}
                                className={classnames(styles.sliderButton, activeSliderButton === 1 && styles.activeSliderButton)}
                            >
                                Apollo.io
                            </h3>
                            <h3
                                onClick={() => experienceSliderClicked(2)}
                                className={classnames(styles.sliderButton, activeSliderButton === 2 && styles.activeSliderButton)}
                            >
                                BlueStacks
                            </h3>
                            <h3
                                onClick={() => experienceSliderClicked(3)}
                                className={classnames(styles.sliderButton, activeSliderButton === 3 && styles.activeSliderButton)}
                            >
                                myBlueprint
                            </h3>
                        </div>
                    </div>
                    <div className={styles.jobDetails}>{renderJob()}</div>
                </div>
            </div>
            <div id='projectSection' className={styles.projectsSection}>
                <img className={styles.paletteIcon} src={paletteIcon} alt='Palette icon' />
                <h3 className={classnames(styles.gradientText, styles.projectsTag)}>PROJECTS</h3>
                <h2 className={styles.projectsHeader}>
                    Some of my <span className={styles.gradientText}>projects</span>, under the spotlight
                </h2>
                <p className={styles.projectsDescription}>
                    Here are some of the projects that I’ve working on for the past little bit. Feel free to click on any of the
                    projects below to get more information about them.
                </p>
                <div className={styles.projectCardsContainer}>
                    <Project
                        title='Beacon'
                        tools={['React', 'Python', 'Flask', 'MongoDB', 'SCSS']}
                        coverImage={beaconCoverImage}
                        githubLink='https://github.com/harshithl1777/beacon'
                        appLink='https://trybeacon.herokuapp.com'
                        mainLink='https://github.com/harshithl1777/beacon'
                        description='Everyday, thousands of consumers have to think about which grocery store to go to and whether or not the store will have the items they need in stock, all because of the pandemic. That’s why we built Beacon - to help consumers get access to accurate, crowdsourced store data including lines and stock information for free of charge so that everyone can shop easier.'
                    />
                    <Project
                        title='Blitz'
                        tools={['React', 'Redux', 'Firebase', 'Chakra UI']}
                        coverImage={blitzCoverImage}
                        appLink='https://tryblitz.vercel.app'
                        githubLink='https://github.com/harshithl1777/blitz'
                        mainLink='https://github.com/harshithl1777/blitz'
                        description="Taking attendance is one of the most time consuming and complicated things in school. For starters, teachers have read through the whole list of students in the class which takes up a lot of time, especially for 1-hour classes. That's where Blitz comes in - a fully functional web app that provides realtime attendance sessions for instructors and students. "
                    />
                    <Project
                        title='Roadrunner'
                        tools={['React', 'Node.js', 'Express', 'PostgreSQL']}
                        coverImage={roadrunnerCoverImage}
                        githubLink='https://github.com/harshithl1777/roadrunner'
                        mainLink='https://github.com/harshithl1777/roadrunner'
                        description='Roadrunner is a middleware data processing service application between Trello and Google Sheets using Trello Webhooks to account for a realtime functionality and user experience. It was originally made for an internship as an internal tool and was deployed on Heroku. It also makes use of the Trello and Google Sheets APIs.'
                    />
                    <Project
                        title='Polaris Data Visualization'
                        tools={['HTML', 'Python', 'Django', 'PostgreSQL']}
                        coverImage={polarisCoverImage}
                        githubLink='https://github.com/kir12/polaris-visualization'
                        mainLink='https://github.com/kir12/polaris-visualization'
                        description='As part of the MHacks 11 hackathon, our team built a product that took on the challenge of plotting and organizing over 50,000 data points from Polaris in an informative way. The project involved Pandas, Python and basic front-end with more emphasis placed on the backend data processing architecture. The points were then displayed on to the Google Maps API.'
                    />
                </div>
            </div>
            <div id='contactSection' className={styles.contactSection}>
                <img className={styles.contactIcon} src={contactIcon} alt='Email icon' />
                <h3 className={classnames(styles.gradientText, styles.contactTag)}>CONTACT ME</h3>
                <h2 className={styles.contactHeader}>
                    If you want to reach out, my inbox is
                    <span className={styles.gradientText}> always open</span>
                </h2>
                <p className={styles.contactDescription}>
                    I’m always on the lookout for new opportunities{' '}
                    <span className='bold'>(especially ones that involve new technologies and challenges)</span> so feel free to
                    send me an email and I’ll make sure to respond as soon as I can.
                </p>
                <Button className={styles.contactButton} type='primary'>
                    <a href='mailto:hello@harshith.dev'>Get in touch</a>
                </Button>
            </div>
            <div className={styles.footerBar}>
                <p className={styles.footerText}>
                    Built with ☕️ in <span className='bold'>open source</span>
                </p>
                <div className={styles.footerIconContainer}>
                    <a href='https://www.linkedin.com/in/harshithlatchupatula/'>
                        <img src={linkedInIcon} alt='LinkedIn icon' className={styles.footerIcon} />
                    </a>
                    <a href='https://dribbble.com/harshithl1777'>
                        <img src={dribbbleIcon} alt='Dribbble icon' className={styles.footerIcon} />
                    </a>
                    <a href='https://devpost.com/harshithl1777'>
                        <img src={devpostIcon} alt='Devpost icon' className={styles.footerIcon} />
                    </a>
                    <a href='mailto:hello@harshith.dev'>
                        <img src={emailIcon} alt='Email icon' className={styles.footerIcon} />
                    </a>
                    <a href='https://github.com/harshithl1777'>
                        <img src={githubIcon} alt='Github icon' className={styles.footerIcon} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default App;
