import classnames from 'classnames';
import styles from 'components/Button/Button.module.scss';

const Button = ({ type, children, onClick, icon, iconPlacement, className }) => {
	return (
		<button onClick={onClick} className={classnames(styles.button, styles[type], className)}>
			<div className={styles.buttonContent}>
				{iconPlacement === 'front' && icon}
				<h1 className={styles.buttonText}>{children}</h1>
				{iconPlacement === 'back' && icon}
			</div>
		</button>
	);
};

export default Button;
